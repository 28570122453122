import React, {useContext, useEffect, useState} from 'react';
import {MessageContext} from '../../components/Contexts/MessageContext';
import './MessagePage.css';
import Header from "../../components/Header/Header";
import CollapsedSearchPanel from '../../components/CollapsedSearchPanel/CollapsedSearchPanel';
import SearchParamsContext from '../../components/Contexts/SearchParamsContext';
import {searchAPI} from "../../api/apiServices";

function MessagePage() {

    const SearchParamsFromContext = useContext(SearchParamsContext);

    const {
        setMessagesData, messagesData, currentPage, setCurrentPage, totalMessages, setTotalMessages
    } = useContext(MessageContext);

    const [activeMessageId, setActiveMessageId] = useState(messagesData && messagesData.length > 0 ? messagesData[0].record_id : null);
    const [searchParams, setSearchParams] = useState(SearchParamsFromContext);

    const {
        transientName, ra, dec, ang, eventType, physicalObject, messengerType,
    } = searchParams;


    const [loadedMessages, setLoadedMessages] = useState([]);
    const itemsPerPage = 10;
    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    const readFromLocalStorage = (key) => {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    };

    useEffect(() => {
        if (messagesData.length > 0) {
            saveToLocalStorage('messagesData', messagesData);
            saveToLocalStorage('activeMessageId', activeMessageId);
            saveToLocalStorage('totalMessages', totalMessages);
            saveToLocalStorage('searchParams', searchParams);
        }
    }, [messagesData, activeMessageId, totalMessages, searchParams]);

    useEffect(() => {
        const savedMessages = readFromLocalStorage('messagesData');
        const loadedActiveMessageId = readFromLocalStorage('activeMessageId');
        const loadedTotalMessages = readFromLocalStorage('totalMessages');
        const loadedSearchParams = readFromLocalStorage('searchParams');

        if (savedMessages && savedMessages.length > 0) {
            setMessagesData(savedMessages);
            setActiveMessageId(loadedActiveMessageId);
            setTotalMessages(loadedTotalMessages);
            setSearchParams(loadedSearchParams);
        }
    }, []);
    useEffect(() => {
        setLoadedMessages(messagesData);
    }, [messagesData]);

    const loadMoreData = async () => {
        setIsLoading(true);
        const newPage = currentPage + 1;
        const newData = await searchAPI(transientName, ra, dec, ang, physicalObject, eventType, messengerType, newPage);


        setMessagesData([...messagesData, ...newData.records]);
        setCurrentPage(newPage);
        setIsLoading(false);
    };
    const [isLoading, setIsLoading] = useState(false);


    const handleShowMore = () => {
        loadMoreData();
    };

    useEffect(() => {
        const mainElement = document.querySelector('.main');
        const offsetTop = mainElement.offsetTop;

        const onScroll = () => {
            if (window.scrollY > offsetTop) {
                mainElement.classList.add('fixed');
            } else {
                mainElement.classList.remove('fixed');
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, []);


    const activeMessage = messagesData && messagesData.find(msg => msg.record_id === activeMessageId);


    const handleCardClick = (id) => {
        setActiveMessageId(id);
    };

    function trimChannelIdFromTitle(title = '') {
        let trimmedTitle = title.trim().replace(/^ATel \d+:\s*/, '');
        return trimmedTitle.trim();
    }

    function formatDate(inputDate) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const date = new Date(inputDate);

        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');

        const offset = date.getTimezoneOffset();

        const offsetSign = offset > 0 ? '-' : '+';
        const offsetHours = Math.floor(Math.abs(offset) / 60);
        const offsetMinutes = Math.abs(offset) % 60;

        let timeLabel;
        if (offset === 0) {
            timeLabel = 'UT';
        } else if (offsetMinutes === 0) {
            timeLabel = `UT${offsetSign}${offsetHours}`;
        } else {
            timeLabel = `UT${offsetSign}${offsetHours}:${offsetMinutes}`;
        }

        return `${day} ${month} ${year}; ${hours}:${minutes} ${timeLabel}`;
    }

    const processDescription = (description) => {
        const trimmedDescription = description && description.endsWith('...') ? description.slice(0, -3) : description;

        return (<>
            {trimmedDescription}
            {activeMessage && activeMessage.link && (
                <a href={activeMessage.link} target="_blank" rel="noopener noreferrer">
                    More...
                </a>)}
        </>);
    };

    function generateTagList(activeMessage) {
        const listItems = [];

        const addItem = (item) => {
            if (Array.isArray(item) && item.length > 0) {
                item.forEach(value => {
                    const trimmedValue = value.trim();
                    if (trimmedValue) {
                        listItems.push(<li key={value}>{value}</li>);
                    }
                });
            } else if (typeof item === 'string' && item.trim()) {
                listItems.push(<li key={item}>{item}</li>);
            }

        };

        if (activeMessage) {
            addItem(activeMessage.object_name);
            addItem(activeMessage.messenger_type);
            addItem(activeMessage.object_type);
            addItem(activeMessage.event_type);
            if (activeMessage.coordinate_system && activeMessage.coordinates) {
                addItem(`${activeMessage.coordinate_system}: ${activeMessage.coordinates}`);
            }
        }

        return listItems;
    }

    function ActiveMessageTagList({activeMessage}) {
        if (!activeMessage) {
            return null;
        }

        const listItems = generateTagList(activeMessage);
        return (<ul className={"tagList"}>
            {listItems.length > 0 ? listItems : <li>No data available</li>}
        </ul>);
    }


    if (!messagesData) {
        return <div>Loading...</div>;
    }

    const searchString = [transientName && `${transientName}`, ra && `RA=${ra}`, dec && `DEC=${dec}`, ang && `RAD=${ang}`, physicalObject && `${physicalObject}`, eventType && `${eventType}`, messengerType && `${messengerType}`].filter(Boolean).join(', ');


    return (<div className="app-container font-base">
        <div className="container">
            <Header/>
            <CollapsedSearchPanel searched={searchString} total={totalMessages}/>
            <div className="columns">

                <div className="sidemenu">
                    <ul className="cards-list">
                        {loadedMessages && loadedMessages.map((msg) => (<li
                            key={msg.record_id}
                            className={`card-item ${msg.record_id === activeMessageId ? 'active-card' : ''}`}
                            onClick={() => handleCardClick(msg.record_id)}
                        >
                            <div className="card-header">
                                <div className="card-date">{formatDate(msg.date)}</div>
                                <div className="card-id">
                                    {msg.provider === "atel" ? `ATel${msg.record_id}` : null}
                                    {msg.provider === "gcn" ? `GCN${msg.record_id.replace('neg', '').split('.')[0]}` : null}
                                </div>

                            </div>
                            <div className="card-title">{trimChannelIdFromTitle(msg.title)}</div>
                        </li>))}
                    </ul>
                    {loadedMessages.length < totalMessages && (<button onClick={handleShowMore} disabled={isLoading}>
                            {isLoading ? 'Loading...' : `Show more ( ${totalMessages - loadedMessages.length} )`}
                        </button>

                    )}
                </div>

                <div className="main">
                    {activeMessage ? (<>
                        <ActiveMessageTagList activeMessage={activeMessage}/>
                        <div className="main-card-header">
                            <div className="main-card-date">{formatDate(activeMessage.date)}</div>
                            <div className="main-card-id">
                                {activeMessage.provider === "atel" ? `ATel${activeMessage.record_id}` : null}
                                {activeMessage.provider === "gcn" ? `GCN${activeMessage.record_id.replace('neg', '').split('.')[0]}` : null}
                            </div>

                        </div>
                        <h2 className="main-card-title">{trimChannelIdFromTitle(activeMessage.title)}</h2>

                        <p className={"main-card-message"}>
                            {activeMessage && activeMessage.description ? processDescription(activeMessage.description) : "No description available."}
                        </p>

                        <p className={"main-card-creator"}>{activeMessage.creator}</p>
                    </>) : (<p>No messages</p>)}
                </div>
            </div>
        </div>
    </div>);
}

export default MessagePage;
